import React, { useCallback, useEffect, useRef, useState } from "react";
import { SuccessModal, ErrorModal, PaymentModal } from "../../components/Modal";
import numberWithSpaces from "../../helpers/numberWithSpaces";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NoDollar from "../CreateProducts/NoDollar";
import Layout from "../../components/Layout";
import Button from "../../components/Button";
import ViewItemStyle from "./ViewItemStyle";
import { useSelector } from "react-redux";
import { get } from "lodash";
import api from "../../api";
import { roundCurrency } from "../../utils";
import moment from "moment";
import customFuncsApi from "../../api/index";

const ViewItem = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const successModalRef = useRef();
	const errorRef = useRef();
	const noDollarRef = useRef();
	const paymentRef = useRef();

	const { getMe } = useSelector((state) => state.main);
	const data = get(location, "state", {});
	const DocEntry = get(location, "state.DocEntry", 0);

	const getNoDollarRef = useCallback((ref) => {
		noDollarRef.current = ref;
	}, []);

	const [tableData, setTableData] = useState([]);
	const [tableData2, setTableData2] = useState([]);
	const [deviceInfoData, setDeviceInfoData] = useState([]);
	const [downloadData, setDownloadData] = useState({ state: location.state });
	const [info1, setInfo1] = useState("");
	const [info2, setInfo2] = useState("");

	const [sumPay, setSumPay] = useState(0);
	const [dollar, setDollar] = useState(1);

	const [isLoading, setIsLoading] = useState(false);
	const [isLoading2, setIsLoading2] = useState(false);

	useEffect(() => {
		getInformationOfItem();
		getInfoOFDevice();
	}, []);

	const getInformationOfItem = () => {
		setIsLoading(true);
		customFuncsApi
			.get(`xsSql/getInstallments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: "odata.maxpagesize=50",
				},
			})
			.then((res) => {
				const resData = get(res.data, "value", []);
				setTableData2(resData);
				setTableData(resData);
				setIsLoading(false);
				let a = resData;
				let allSum = 0;
				for (let i = 0; i < a.length; i++) {
					allSum = allSum + a[i].PaidToDate;
				}
				setSumPay(allSum);
			})
			.catch((err) => {
				errorRef.current?.open(get(err.response.data, "error.message", ""));
			});
	};

	const getUSD = () => {
		api
			.post(`SBOBobService_GetCurrencyRate`, {
				Currency: "UZS",
				Date: moment(),
			})
			.then((res) => {
				setDollar(JSON.parse(res.data));
			})
			.catch((err) => {
				alert("Курс не загружен. Пожалуйста, введите курс");
			});
	};

	useEffect(() => {
		getUSD();
	}, []);

	const getInfoOFDevice = () => {
		setIsLoading2(true);

		api
			.get(`Invoices(${DocEntry})`)
			.then((res) => {
				const resData = get(JSON.parse(res.data), "DocumentLines[0]", []);
				getItems(get(resData, "ItemCode", ""));
				setDeviceInfoData(resData);
				setIsLoading2(false);
				setDownloadData(JSON.parse(res.data));
			})
			.catch((err) => {
				errorRef.current?.open(get(JSON.parse(err.response.data), "error.message", ""));
			});
	};

	const getItems = (code) => {
		api
			.get(`Items('${code}')`)
			.then((res) => {
				setInfo1(get(JSON.parse(res.data), "U_Condition", "Состояние не найден"));
				setInfo2(get(JSON.parse(res.data), "U_Color", "Цвет не найден"));
			})
			.catch((err) => {
				errorRef.current?.open(get(JSON.parse(err.response.data), "error.message", ""));
			});
	};

	const createPayment = (sum, typeOfPayment, valuta, courseDollar, cashAccount) => {
		setIsLoading(true);
		setDollar(Number(courseDollar));
		let kiritilganSum = valuta === "UZS" ? Number(sum) / Number(courseDollar) : Number(sum);
		let kiritilganSum2 = valuta === "UZS" ? Number(sum) / Number(courseDollar) : Number(sum);

		let filteredData = tableData.filter((v) => {
			return v.InsTotal !== v.PaidToDate;
		});

		let filteredData2 = tableData2.filter((v) => {
			return v.InsTotal !== v.PaidToDate;
		});

		let aa = [...filteredData];
		let goingBack = JSON.parse(JSON.stringify(filteredData));

		for (let i = 0; i < filteredData.length; i++) {
			let left = Number(filteredData[i].InsTotal);
			let right = Number(filteredData[i].PaidToDate);

			let left2 = Number(filteredData2[i].InsTotal);
			let right2 = Number(filteredData2[i].PaidToDate);

			if (kiritilganSum + right >= left) {
				aa[i].PaidToDate = left;
				goingBack[i].PaidToDate = Number(goingBack[i].InsTotal) - Number(goingBack[i].PaidToDate);
				kiritilganSum = kiritilganSum - (left - right);
				kiritilganSum2 = kiritilganSum2 + right2 - left2;
			} else if (kiritilganSum + right < left) {
				aa[i].PaidToDate = kiritilganSum + right;
				goingBack[i].PaidToDate = kiritilganSum2;
				kiritilganSum = 0;
				kiritilganSum2 = 0;
			}
		}

		let dataPFilter = goingBack.filter((v) => {
			return v.PaidToDate > 0;
		});

		let dataPFilterMain = dataPFilter.map((v) => {
			return {
				DocEntry: DocEntry, // Qaysi sotuvga to'lanyapti.
				InstallmentId: v.InstlmntID, // birinchi oyga
				SumApplied: v.PaidToDate, // birinchi oyga to'lo'v
			};
		});

		api
			.post(`IncomingPayments`, {
				CardCode: get(data, "CardCode", 0), // Kim to'layapti. BusinessPartner->CardCode
				CashSum: Number(sum),
				CashAccount: cashAccount ? cashAccount.split(" , ")[1] : getMe[typeOfPayment],
				PaymentInvoices: dataPFilterMain,
				BankChargeAmount: 0,
				DocRate: valuta === "UZS" ? courseDollar : undefined,
				DocCurrency: valuta,
			})
			.then(() => {
				setIsLoading(false);
				successModalRef.current?.open("Платеж успешно произведен!");
			})
			.catch((err) => {
				setIsLoading(false);
				errorRef.current?.open(get(JSON.parse(err.response.data), "error.message", ""));
			});
	};

	const cancel = () => {
		navigate(-1);
	};

	return (
		<Layout>
			<ViewItemStyle>
				<div className="container">
					<div className="grid grid-cols-1 w-full xl:grid-cols-3 gap-6">
						<div className="grid col-span-2 sm:grid-cols-2 gap-6 mb-8 h-fit">
							<div>
								<p className={"font-medium text-zinc-600 text-sm mb-1"}>ФИО</p>
								<input
									type="text"
									className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
									value={`${get(data, "CardName", "Покупатель")}`}
									disabled={true}
								/>
							</div>

							<div>
								<p className={"font-medium text-zinc-600 text-sm mb-1"}>Продукт</p>
								<input
									type="text"
									className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
									value={`${get(data, "Dscription", "Товар")}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={"font-medium text-zinc-600 text-sm mb-1"}>Кому принадлежит телефон</p>
								<input
									type="text"
									className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
									value={`${get(data, "U_Owner", "-")}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={"font-medium text-zinc-600 text-sm mb-1"}>Гарант</p>
								<input
									type="text"
									className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
									value={`${get(data, "U_Guarantor", "-")}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={"font-medium text-zinc-600 text-sm mb-1"}>IMEI</p>
								<input
									type="text"
									className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
									value={`${get(deviceInfoData, "SerialNumbers[0].InternalSerialNumber", 0)}`}
									disabled={true}
								/>
							</div>

							<div>
								<p className={"font-medium text-zinc-600 text-sm mb-1"}>Серийный номер</p>
								<input
									type="text"
									className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
									// value={`${get(
									//   deviceInfoData,
									//   'SerialNumbers[0].InternalSerialNumber',
									//   0,
									// )}`}
									value={`${get(deviceInfoData, "SerialNum", 0)}`}
									disabled={true}
								/>
							</div>

							<div>
								<p className={"font-medium text-zinc-600 text-sm mb-1"}>Цвет</p>
								<input
									// type="text"
									className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
									value={info2}
									disabled={true}
								/>
							</div>

							<div>
								<p className={"font-medium text-zinc-600 text-sm mb-1"}>Состояние</p>
								<input
									// type="text"
									className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
									value={info1}
									disabled={true}
								/>
							</div>

							<div>
								<p className={"font-medium text-zinc-600 text-sm mb-1"}>Текущая цена</p>
								<input
									type="number"
									placeholder="Текущая цена"
									className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
									value={`${get(deviceInfoData, "GrossBuyPrice", "Текущая цена не найден")}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={"font-medium text-zinc-600 text-sm mb-1"}>Цена</p>
								<input
									type="number"
									placeholder="Цена"
									className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
									value={`${get(deviceInfoData, "GrossTotal", "Цена не найден")}`}
									disabled={true}
								/>
							</div>
						</div>

						<divx>
							<div className="flex flex-col sm:flex-row gap-2 sm:gap-6">
								<p>
									<span className="font-bold text-zinc-900">Курс:</span> {numberWithSpaces(dollar)}{" "}
									UZS
								</p>
								<p>
									<span className="font-bold text-zinc-900">Долг:</span>{" "}
									<span className="debt">
										-{(get(deviceInfoData, "GrossTotal", 0) - sumPay).toFixed(2)}
									</span>{" "}
									USD
								</p>
							</div>
							<div className="center py-5">
								<Button
									btnStyle={{ backgroundColor: "#243AB5" }}
									onClick={() => paymentRef.current?.open()}
									isLoading={isLoading || isLoading2}
									hoverBtnStyle={{ backgroundColor: "#243AB5" }}
								>
									Оплатить
								</Button>
							</div>

							<div className="overflow-y-auto mb-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												N
											</th>
											<th scope="col" className="px-6 py-4">
												Дата
											</th>
											<th scope="col" className="px-6 py-4">
												Оплата
											</th>
											<th scope="col" className="px-6 py-4">
												Оплачено
											</th>
										</tr>
									</thead>
									<tbody>
										{tableData.map((v, i) => {
											let a = v.DueDate.split("");
											a.splice(4, 0, "-");
											a.splice(7, 0, "-");
											a.join();
											return (
												<tr className="bg-white border-b  hover:bg-gray-50 " key={i}>
													<td className="px-6 py-4 font-medium text-gray-900 ">{i}</td>
													<td className="px-6 py-4 whitespace-nowrap">{a}</td>
													<td className="px-6 py-4">{Number(v.InsTotal).toFixed(2)}</td>
													<td className="px-6 py-4">{roundCurrency(v.PaidToDate)}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</divx>
					</div>

					<div className="bottomCard justify-end mt-8">
						<Button btnStyle={{ backgroundColor: "red" }} onClick={cancel}>
							Назад
						</Button>

						<Button
							btnStyle={{ marginLeft: 20, width: 200 }}
							onClick={() => {
								if (window.location.hostname.toLowerCase().includes("istyle")) {
									navigate("/ru_download", {
										state: { data: downloadData, user: data, tableData },
									});
								} else {
									navigate("/download", {
										state: { data: downloadData },
									});
								}
							}}
						>
							Загрузить договор(Ру)
						</Button>
						{window.location.hostname.toLowerCase().includes("istyle") && (
							<Button
								btnStyle={{ marginLeft: 20, width: 225 }}
								onClick={() =>
									navigate("/uz_download", {
										state: { data: downloadData, user: data, tableData },
									})
								}
							>
								Шартномани юклаш
							</Button>
						)}
					</div>
				</div>
			</ViewItemStyle>
			<>
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<PaymentModal
					getRef={(r) => (paymentRef.current = r)}
					onConfirm={(sum, typeOfPayment, valuta, courseDollar, cashAccount) =>
						createPayment(sum, typeOfPayment, valuta, courseDollar, cashAccount)
					}
					onClose={() => paymentRef.current?.close()}
				/>
				<SuccessModal
					getRef={(r) => (successModalRef.current = r)}
					onConfirm={() => successModalRef.current?.close()}
				/>
				<NoDollar getRef={getNoDollarRef} />
			</>
		</Layout>
	);
};

export default ViewItem;
